import React from 'react';
import { Router as MyRouter } from '@reach/router';
import Layout from '@/layouts/main';
import loadable from '@loadable/component';
import { Mixpanel } from '@/utils';

// Use Gatsby's dynamic import syntax for code splitting
const OurBlog = loadable(() => import(`@/views/home/blog/blog-page`));
const BlogArticle = loadable(
  () => import(`@/views/home/blog/blog-page/blog-article`),
);

function Router() {
  React.useEffect(() => Mixpanel.track(`View Blog Page`), []);

  return (
    <Layout
      title="Blog - Transforming your health through your gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health outcomes of people living with anxiety and depression."
      active="blog-page"
    >
      <MyRouter>
        <OurBlog path="/blogs" />
        <BlogArticle path="/blogs/:articleId" />
      </MyRouter>
    </Layout>
  );
}

export default Router;
